import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getcompetitionguidelinesData,
  getSliderData,
} from "../competitionguidelines/competitionguidelinesSlice";
import { useNavigate } from "react-router-dom";
import ContentPageCarousel from "../contentpagecarousel/contentpagecarousel";

const { REACT_APP_API_URL } = process.env;

export function CompletionGuildlines() {
  const mounted = useSelector(
    (state) => state?.competitionguidelinesData?.mounted
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!mounted) {
      getCompetitionGuidelines();
      //getSlider();
    }
  });
  const competitionGuidelines = useSelector(
    (state) => state.competitionguidelinesData.competitionguidelines
  );

  // const slider = useSelector((state) => state?.competitionguidelinesData?.slider);

  const slider = useSelector(
    (state) => state?.competitionguidelinesData?.competitionguidelines?.slider
  );

  const getCompetitionGuidelines = () => {
    fetch(
      `${REACT_APP_API_URL}page/detail/YOUTH_NON_AFFILIATED_ID/YOUTH_NON_AFFILIATED`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getcompetitionguidelinesData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSlider = () => {
    fetch(`${REACT_APP_API_URL}fe-contentpage-slider`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getSliderData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container className="fluidContainer padLeftNone">
        <div className="joinOurTeamWrapper">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* <Joinourteamcarousel /> */}

              {!!slider && <ContentPageCarousel images={slider} />}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="competitionGuildWrapper">
                <Typography variant="h1">
                  {competitionGuidelines.title}
                </Typography>
                <ul>
                  {competitionGuidelines.register_pdf &&
                  competitionGuidelines.register_pdf !== "" ? (
                    <li>
                      <a
                        href={competitionGuidelines.url_register_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        How to Register?
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.policie_pdf ? (
                    <li>
                      <a
                        href={competitionGuidelines.url_policie_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Policies
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.youth_cat_division_pdf ? (
                    <li>
                      <a
                        href={competitionGuidelines.url_youth_cat_division_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Youth Categories &amp; Divisions
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.youth_cheer_rule_score_pdf ? (
                    <li>
                      <a
                        href={
                          competitionGuidelines.url_youth_cheer_rule_score_pdf
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Youth Cheer Rules &amp; Scoring
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.youth_dance_rule_score_pdf ? (
                    <li>
                      <a
                        href={
                          competitionGuidelines.url_youth_dance_rule_score_pdf
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Youth Dance Rules &amp; Scoring
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.na_cat_division_pdf ? (
                    <li>
                      <a
                        href={competitionGuidelines.url_na_cat_division_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        NA Categories &amp; Divisions
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.na_cheer_rule_score_pdf ? (
                    <li>
                      <a
                        href={competitionGuidelines.url_na_cheer_rule_score_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        NA Cheer Rules &amp; Scoring
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.na_dance_rule_score_pdf ? (
                    <li>
                      <a
                        href={competitionGuidelines.url_na_dance_rule_score_pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        NA Dance Rules &amp; Scoring
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.yacda_membership ? (
                    <li>
                      <a
                        href={competitionGuidelines.yacda_membership}
                        target="_blank"
                        rel="noreferrer"
                      >
                        YCADA Membership
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {competitionGuidelines.yacda_video_library ? (
                    <li>
                      <a
                        href={competitionGuidelines.yacda_video_library}
                        target="_blank"
                        rel="noreferrer"
                      >
                        YCADA Video Library
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <div className="rulesContent">
                  <Typography variant="h2">Rules Questions?</Typography>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => navigate("/rules-and-questions")}
                  >
                    Click Here
                  </Button>
                  <div
                    className="ckeditor_content_render"
                    dangerouslySetInnerHTML={{
                      __html: competitionGuidelines.content,
                    }}
                  ></div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default CompletionGuildlines;
