import { Button, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getConference } from '../conferencesdetail/conferences.function';

export function ConferenceAccomodation() {

    const params = useParams();
    const dispatch = useDispatch();
    const [conferenceId, saveConferencesId] = useState(0);
    const accommodationTitle = useSelector((state) => state.conferenceDetails.conference.conferenceaccommodations.accommodationTitle);
    const accommodationContent = useSelector((state) => state.conferenceDetails.conference.conferenceaccommodations.accommodationContent);
    const accommodationDate = useSelector((state) => state.conferenceDetails.conference.conferenceaccommodations.accommodationDate);
    useEffect(() => {
        if (params) {
            const conferenceId = params.id;
            saveConferencesId(conferenceId);
            dispatch(getConference(conferenceId));
        }
    }, [dispatch, params]);

    const goBack = () => {
        window.history.back();
    }

    return (
        <>
            <Container spacing={2} className='containerFe'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className='accomodationWrapper'>
                        <div className='accomodationTopHead'>
                            <Typography variant='h1'>Accommodations</Typography>
                            <div className='backButton'>
                                <Button variant='contained' disableElevation onClick={goBack}>Go Back</Button>
                            </div>
                        </div>
                        <Typography variant='h3'>{accommodationTitle} | <span>{accommodationDate}</span></Typography>
                        <div style={{ float:'left', display:'block' }} dangerouslySetInnerHTML={{ __html: accommodationContent }}></div>
                    </div>
                </Grid>
            </Container>
        </>
    )
}

export default ConferenceAccomodation;
