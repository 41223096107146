import styled from "@emotion/styled";
import {
  Alert,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import validator from "validator";
import Ycadacertified from "../ycadacertified/ycadacertified";
import CloseIcon from "@mui/icons-material/Close";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import ContentPageCarousel from "../contentpagecarousel/contentpagecarousel";
import { getSliderData } from "../contentpagecarousel/contentpagecarouselSlice";
import { useDispatch, useSelector } from "react-redux";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "50px",
    textTransform: "capitalize",
    color: "#919191",
    fontFamily: ['"Mulish", sans-serif !important'],
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ['"Mulish", sans-serif !important'].join(","),
    "&:focus": {
      border: "1px solid #E8E8E8",
      boxShadow: "0px 4px 20px rgba(128, 75, 128, 0.2)",
    },
  },
}));

export function Judges() {
  const dispatch = useDispatch();
  const slider = useSelector((state) => state?.contentbanner?.slider);

  const { REACT_APP_API_URL } = process.env;
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameHelperText, setLastNameHelperText] = useState("");
  const [contactNumberError, setContactNumberError] = useState(false);
  const [contactNumberHelperText, setContactNumberHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [messageHelperText, setMessageHelperText] = useState("");
  const [apimessage, setapiMessage] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  let checkError = [];
  const { vertical, horizontal } = snackbarState;
  const snackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const [values, setValues] = useState({
    textmask: "",
  });
  const [textAreaCount, setTextAreaCount] = React.useState(0);

  const validateFirstName = (firstName) => {
    if (firstName !== "") {
      setFirstNameError(false);
      setFirstNameHelperText("");
    } else {
      setFirstNameError(true);
      setFirstNameHelperText("Enter First Name.");
      checkError.push("Enter First Name.");
    }
  };

  const validateLastName = (lastName) => {
    if (lastName !== "") {
      setLastNameError(false);
      setLastNameHelperText("");
    } else {
      setLastNameError(true);
      setLastNameHelperText("Enter Last Name.");
      checkError.push("Enter Last Name.");
    }
  };

  const onChangeValidatePhoneNumber = (e) => {
    const contactNumber = e.target.value;
    validateContactNumber(contactNumber);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const validateContactNumber = (contactNumber) => {
    if (!contactNumber) {
      setContactNumberError(true);
      setContactNumberHelperText("Enter Phone Number.");
      checkError.push("Enter Phone Number.");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length >= 10 &&
      contactNumber.length < 15
    ) {
      setContactNumberError(false);
      setContactNumberHelperText("");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length === 15
    ) {
      setContactNumberError(false);
      setContactNumberHelperText("");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length > 15
    ) {
      setContactNumberError(true);
      setContactNumberHelperText("Enter maximum 15 digits.");
      checkError.push("Enter maximum 15 digits.");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length < 10
    ) {
      setContactNumberError(true);
      setContactNumberHelperText("Enter 10 digit phone number");
      checkError.push("Enter 10 digit phone number");
    }
  };

  const verifyEmail = (e) => {
    const email = e.target.value;
    validateEmail(email);
  };

  const validateEmail = (email) => {
    if (!email) {
      setEmailError(true);
      setEmailHelperText("Email is required.");
      checkError.push("Email is required.");
    } else if (email && validator.isEmail(email)) {
      setEmailError(false);
      setEmailHelperText("");
      return true;
    } else {
      setEmailError(true);
      setEmailHelperText("Email is incorrect, Enter a valid email.");
      checkError.push("Email is incorrect, Enter a valid email.");
    }
  };

  const verifyMessage = (e) => {
    const message = e.target.value;
    validateMessage(message);
  };

  const validateMessage = (message) => {
    if (message !== "") {
      setMessageError(false);
      setMessageHelperText("");
    } else {
      setMessageError(true);
      setMessageHelperText("Enter Message.");
      checkError.push("Enter Message.");
    }
    recalculate(message);
  };

  const recalculate = (message) => {
    console.log("event value:", message);
    setTextAreaCount(message.length);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
    const firstName = removeExtraSpace(e.nativeEvent.target[0].value);
    const lastName = removeExtraSpace(e.nativeEvent.target[1].value);
    const contactNumber = removeExtraSpace(e.nativeEvent.target[2].value);
    const email = removeExtraSpace(e.nativeEvent.target[3].value);
    const message = removeExtraSpace(e.nativeEvent.target[4].value);

    validateFirstName(firstName);
    validateLastName(lastName);
    validateContactNumber(contactNumber);
    validateEmail(email);
    validateMessage(message);

    if (checkError.length === 0) {
      sendJudgesRequest(firstName, lastName, contactNumber, email, message);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const getSlider = () => {
    fetch(`${REACT_APP_API_URL}fe-contentpage-slider/JUGDE_ID`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getSliderData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSlider();
  }, []);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sendJudgesRequest = (
    firstName,
    lastName,
    contactNumber,
    email,
    message
  ) => {
    const newContactNumber = contactNumber.replace(/\D/g, "");
    setDisableSubmit(true);
    fetch(`${REACT_APP_API_URL}send-judge-request`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        contact: newContactNumber,
        email: email,
        message: message,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.response_code === 200 &&
          responseJson.status === true
        ) {
          setapiMessage(responseJson.message);
          if (responseJson) {
            setDisableSubmit(false);
            setTextAreaCount(0);
            setSnackbarState({ ...snackbarState, open: true });
            cancelJudges();
          }
        } else {
          setDisableSubmit(false);
          setapiMessage(responseJson.errors.first_name[0]);
          if (responseJson) {
            setSnackbarState({ ...snackbarState, open: true });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelJudges = () => {
    document.getElementById("judges-form").reset();
  };
  return (
    <>
      <Container className="fluidContainer padLeftNone">
        <div className="joinOurTeamWrapper">
          <Grid container spacing={2}>
            <Grid item lg={5} md={6} sm={12} xs={12}>
              {/* <Judgescarousel /> */}
              {!!slider && <ContentPageCarousel images={slider} />}
            </Grid>
            <Grid item lg={7} md={6} sm={12} xs={12}>
              <div>
                <Typography variant="h1">judges</Typography>
                <Typography variant="body1">
                  Do you have experience judging youth cheer &amp; dance?{" "}
                  <span>High Pointz is interested in speaking with you.</span>
                </Typography>
                <div className="joinOurTeamForm">
                  <form id="judges-form" onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="topSpace"
                      >
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel
                            shrink
                            htmlFor="firstname"
                            className="inputLabel"
                          >
                            First name*
                          </InputLabel>
                          <BootstrapInput
                            inputProps={{
                              maxLength: 45,
                            }}
                            id="firstname"
                            className="bootstrapFields"
                            error={firstNameError}
                            onChange={(e) => validateFirstName(e)}
                          />
                          {!!firstNameError && (
                            <FormHelperText error id="firstname">
                              {firstNameHelperText}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="topSpace"
                      >
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel
                            shrink
                            htmlFor="lastname"
                            className="inputLabel"
                          >
                            Last name*
                          </InputLabel>
                          <BootstrapInput
                            inputProps={{
                              maxLength: 45,
                            }}
                            id="lastname"
                            className="bootstrapFields"
                            error={lastNameError}
                            onChange={(e) => validateLastName(e)}
                          />
                          {!!lastNameError && (
                            <FormHelperText error id="lastname">
                              {lastNameHelperText}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="topSpace"
                      >
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel
                            shrink
                            htmlFor="contactnumber"
                            className="inputLabel"
                          >
                            Contact Number*
                          </InputLabel>
                          <BootstrapInput
                            value={values.textmask}
                            id="contactnumber"
                            name="textmask"
                            className="bootstrapFields"
                            error={contactNumberError}
                            onChange={(e) => onChangeValidatePhoneNumber(e)}
                            inputComponent={TextMaskCustom}
                          />
                          {!!contactNumberError && (
                            <FormHelperText error id="contactnumber">
                              {contactNumberHelperText}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="topSpace"
                      >
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel
                            shrink
                            htmlFor="emailaddress"
                            className="inputLabel"
                          >
                            Email Address*
                          </InputLabel>
                          <BootstrapInput
                            id="emailaddress"
                            className="bootstrapFields"
                            error={emailError}
                            onChange={(e) => verifyEmail(e)}
                          />
                          {!!emailError && (
                            <FormHelperText error id="emailaddress">
                              {emailHelperText}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="topSpace"
                      >
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel
                            shrink
                            htmlFor="message"
                            className="inputLabel"
                          >
                            Message*
                          </InputLabel>
                          <TextareaAutosize
                            maxLength={500}
                            id="message"
                            className="bootstrapFields"
                            error={messageError.toString()}
                            onChange={(e) => verifyMessage(e)}
                          />
                          <label className="inputLabel">
                            {" "}
                            {`${textAreaCount}/500`}{" "}
                          </label>
                          {!!messageError && (
                            <FormHelperText error id="message">
                              {messageHelperText}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="topBtnSpace"
                      >
                        <Button
                          disabled={disableSubmit}
                          variant="contained"
                          disableElevation
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  open={snackbarState.open}
                  autoHideDuration={6000}
                  onClose={snackbarClose}
                  action={action}
                  key={vertical + horizontal}
                >
                  <Alert severity="success" variant="filled">
                    {apimessage}
                  </Alert>
                </Snackbar>
              </div>
            </Grid>
            <Grid item lg={5} md={6} sm={12} xs={12}></Grid>
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <div className="ycadaCertifiedBanner">
                <Ycadacertified />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default Judges;
