import { createSlice } from '@reduxjs/toolkit'

export const advertisementSlice = createSlice({
    name: 'advertisementdata',
    initialState: {
        advertisement: {
            type: 0,
            image: '../../assets/images/advertisement-one.png',
            code: "",
            link: "",
        },
        mounted: false,
        error: ''
    },
    reducers: {
        getadvertisementData: (state, action) => {
            return {
                ...state,
                advertisement: action.payload,
                mounted: true,
                error: 'error'
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { getadvertisementData } = advertisementSlice.actions

export default advertisementSlice.reducer