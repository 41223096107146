import { createSlice } from '@reduxjs/toolkit'

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        departments: [],
        departmentsMounted: false,
        error: ''
    },
    reducers: {
        getDepartments: (state, action) => {
            return {
                ...state,
                departments: action.payload.departments,
                departmentsMounted: true,
            }
        }
    }
})

export const { getDepartments } = contactSlice.actions

export default contactSlice.reducer
