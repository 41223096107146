import { createSlice } from '@reduxjs/toolkit'

export const rulesandquestionsSlice = createSlice({
    name: 'rulesandquestionsData',
    initialState: {
        rulesandquestions: {
            title: '',
            content: "",
            slider: "",
        },
        mounted: false,
        error: ''
    },
    reducers: {
        getrulesandquestionsData: (state, action) => {
            return {
                ...state,
                rulesandquestions: action.payload,
                mounted: true,
            }
        },
        getSliderData: (state, action) => {
            return {
                ...state,
                slider: action.payload,
                mounted: true,
            };
        },
    }
})

// Action creators are generated for each case reducer function
export const { getrulesandquestionsData, getSliderData } = rulesandquestionsSlice.actions

export default rulesandquestionsSlice.reducer