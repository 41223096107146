import { createSlice } from '@reduxjs/toolkit'

export const herobannerSlice = createSlice({
    name: 'herobanner',
    initialState: {
        herobannerdata: {
            type: 0,
            image: '../../assets/images/hero-banner-image.png',
            heading: "",
            description: "",
        },
        mounted: false,
        carousel: false,
        error: ''
    },
    reducers: {
        getherobannerData: (state, action) => {
            return {
                ...state,
                herobannerdata: action.payload,
                mounted: true,
                error: 'error'
            }
        },
        setCarousel: (state, action) => {
            return {
                ...state,
                carousel: true
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { getherobannerData, setCarousel } = herobannerSlice.actions

export default herobannerSlice.reducer