import { Button } from "@mui/material";
import React from "react";

export function Ycadacertified() {
  return (
    <>
      <div className="ycadaBannerWrapper">
        <ul>
          <li>
            want to
            <span>
              get <b>YCADA certified?</b>
            </span>
          </li>
          <li>
            <Button
              onClick={(e) => window.open("https://www.ycada.org/", "_blank")}
              variant="contained"
            >
              Click here to learn more
            </Button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Ycadacertified;
