import React from 'react';
import twitterIcon from '../../assets/images/twitter-icon.svg';
import facebookIcon from '../../assets/images/facebook-icon.svg';
import instagramIcon from '../../assets/images/instagram.svg';
import { useSelector } from 'react-redux';

export function Socialmedia() {

    //const twitterLink = useSelector(state => state.header.websiteSettings.twitter_link)
    const facebookLink = useSelector(state => state.header.websiteSettings.facebook_link)
    //const instagramLink = useSelector(state => state.header.websiteSettings.instagram_link)

    return (
        <>
            {/* <a href={twitterLink} target='_blank' rel="noreferrer"><img src={twitterIcon} alt='' /></a> */}
            <a href={facebookLink} target='_blank' rel="noreferrer"><img src={facebookIcon} alt='' /></a>
            {/* <a href={instagramLink} target='_blank' rel="noreferrer"><img src={instagramIcon} alt='' /></a> */}
        </>
    )
}

export default Socialmedia