import { createSlice } from '@reduxjs/toolkit'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        websiteSettings: {
            id: 0,
            email: "",
            phone_number: "",
            address: "",
            copyright_content: "",
            header_logo: "",
            footer_logo: "",
            facebook_link: "",
            twitter_link: "",
            instagram_link: "",
            created_at: "",
            updated_at: ""
        },
        loading: false,
        error: ''
    },
    reducers: {
        getHeaderDataLoading: (state, action) => {
            return {
                ...state,
                loading: true,
            }
        },
        getHeaderDataSuccess: (state, action) => {
            return {
                ...state,
                websiteSettings: action.payload.websiteSettings,
                loading: false,
                error: 'error'
            }
        },
        getHeaderDataFailure: (state, action) => {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { getHeaderDataLoading, getHeaderDataSuccess, getHeaderDataFailure } = headerSlice.actions

export default headerSlice.reducer