import { Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function Eventstable() {

    const navigate = useNavigate();
    const championship = useSelector(state => state.homeEvents.homeEvents.championship)
    const national = useSelector(state => state.homeEvents.homeEvents.national)
    const conferences = useSelector(state => state.homeEvents.homeEvents.conferences)
    const tabValue = useSelector(state => state.homeEvents.tabValue)
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

    return (
        <React.Fragment>
            <div className='eventHead'>
                <h2>Events</h2>
                <a href='/competition-guidelines'>Competition Guidelines</a>
            </div>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableBody>
                    {
                        tabValue === 0 ? championship.length > 0 ? championship.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: '#F7F7F7' }}
                            >
                                <TableCell scope="row" className='dateCol' sx={{ maxWidth: '195px' }}>
                                    <Typography variant='h3'>{(row.event_date) ? new Date(row.event_date).toLocaleDateString('en-US', dateOptions) : 'TBA'}</Typography>
                                </TableCell>
                                <TableCell className='eventCol'>
                                    <Typography variant='h2'>{row.name.toLowerCase()}</Typography>
                                    <Typography variant='body1'>{row.short_description}</Typography>
                                </TableCell>
                                <TableCell className='actionCol' sx={{ maxWidth: '85px' }}>
                                    <Button variant="contained" disableElevation onClick={() => navigate(`/event/${row.id}`)}>View</Button>
                                </TableCell>
                            </TableRow>
                        )) 
                        : <TableRow><TableCell>'High Pointz Championship Events coming soon!'</TableCell></TableRow>
                        : conferences.length > 0 ? conferences.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: '#F7F7F7' }}
                            >
                                <TableCell scope="row" className='dateCol'>
                                    <Typography variant='h3'>{(row.conference_date) ? new Date(row.conference_date).toLocaleDateString('en-US', dateOptions) : 'TBA'}</Typography>
                                </TableCell>
                                <TableCell className='eventCol'>
                                    <Typography variant='h2'>{row.name.toLowerCase()}</Typography>
                                    <Typography variant='body1'>{row.place}</Typography>
                                </TableCell>
                                <TableCell className='actionCol'>
                                    <Button variant="contained" disableElevation onClick={() => navigate(`/conferences-detail/${row.id}`)}>View</Button>
                                </TableCell>
                            </TableRow>
                        )) 
                        : <TableRow><TableCell>'High Pointz Conferences coming soon!'</TableCell></TableRow> 
                        
                        
                        
                    
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default Eventstable;