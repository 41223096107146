import { Button, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getConference } from './conferences.function';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';


export function ConferencesDetail() {

    const params = useParams();
    const dispatch = useDispatch();
    const [conferenceId, saveConferenceId] = useState(0);
    const conference = useSelector((state) => state.conferenceDetails.conference);
    const navigate = useNavigate();

    useEffect(() => {
        if (params) {
            const conferenceId = params.id;
            saveConferenceId(conferenceId);
            dispatch(getConference(conferenceId));
        }
    }, [dispatch, params]);

    let feeNotesSpacer = "boxSpacer hide";
    if (conference.feeNote !== null) {
        feeNotesSpacer = "boxSpacer";
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    const navigateToAccommodation = (conferenceId) => {
        scrollToTop();
        navigate(`/conference/${conferenceId}/accommodation`);
    };

    return (
        <>
            <div className={'conference' + conferenceId}>
                <Container className='containerFe'>
                    <div className='eventDetailsWrapper'>
                        <div className='eventDetailsContent'>
                            <Grid container spacing={4} className='eventDetails'>
                                <Grid item lg={4} md={5} sm={6} xs={12}>
                                    <div className='eventDetailsImg'>
                                        <img src={conference.conferenceImage} alt='' />
                                    </div>
                                </Grid>
                                <Grid item lg={8} md={7} sm={6} xs={12}>
                                    <div className='eventDetailsTopContent'>
                                        <Typography variant='h3'>{conference.conferenceName}</Typography>
                                        <Typography variant='h4'>{conference.conferenceDate}</Typography>
                                        <Typography variant='h4'>{conference.conferencePlace}</Typography>
                                    </div>
                                    <div className='tabLinks conferencesDetail'>
                                        <ul>
                                            <li>
                                                <Button variant="contained">Register</Button>
                                            </li>
                                            <li className='buttonWrapper'>
                                                <Button variant="contained" onClick={() => window.open(conference.schedulePdfName, '_blank')} className='blackButton'>Schedule</Button>
                                            </li>
                                            <li className='buttonWrapper'>
                                                <Button variant="contained" onClick={() => navigateToAccommodation(`${conferenceId}`)}>Accommodations</Button>
                                            </li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Grid container spacing={4}>
                                        <Grid item lg={6} md={12} sm={6} xs={12}>
                                            <div className='boxContentWrapper'>
                                                <div className='boxHeading'>
                                                    <Typography variant='h4'>Pricing/Fees</Typography>
                                                    <Typography variant='body1'>(per participant)</Typography>
                                                </div>
                                                <div className='boxContent'>
                                                    <ul>
                                                        <li><strong>${conference.feeTen}</strong>/Attendee (registering 1-9)</li>
                                                        <li><strong>${conference.feeNineteen}</strong>/Attendee (registering 10-19)</li>
                                                        <li><strong>${conference.feeTwenty}</strong>/Attendee (registering 20+)</li>
                                                    </ul>
                                                    <div className='boxSpacer'></div>
                                                    <Typography variant='body1'>{conference.feeNote}</Typography>
                                                    <div className={feeNotesSpacer}></div>
                                                    <Typography variant='h5'>DEADLINE (Register &amp; Pay be):</Typography>
                                                    <Typography variant='body1'>{conference.deadlineDate}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item lg={6} md={12} sm={6} xs={12} className='conferencesDetailInner'>
                                            <div className='boxContentWrapper conferencesDetail'>
                                                <div className='boxHeading'>
                                                    <Typography variant='h4'>Refund Rates</Typography>
                                                    <Typography variant='body1'>(if requested by the deadlines shown)</Typography>
                                                </div>
                                                <div className='boxContent'>
                                                    <ul>
                                                        <li><strong>100% Refund by: {conference.fullRefundBy}</strong></li>
                                                        <li><strong>50% Refund by: {conference.halfRefundBy}</strong></li>
                                                        <li><strong>NO Refund after: <span>{conference.noRefund}</span></strong></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <Typography onClick={() => window.open(conference.covidGuidelinesPdfName, '_blank')} variant='h2'>COVID Guidelines</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <div className='googleMap' dangerouslySetInnerHTML={{ __html: conference.mapCode }}></div>
                                    <div className='eventAddress conferencesDetail'>
                                        <Typography variant='h3'>Conference Address</Typography>
                                        <Typography variant='body1'>{conference.conferenceAddress}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ConferencesDetail
