import { createSlice } from '@reduxjs/toolkit'

export const eventSlice = createSlice({
    name: 'homeEvents',
    initialState: {
        homeEvents: {
            championship: [],
            national: [],
        },
        tabValue: 0,
        mounted: false,
        error: ''
    },
    reducers: {
        getHomeEvents: (state, action) => {
            return {
                ...state,
                homeEvents: action.payload,
                mounted: true,
            }
        },
        setTabValue: (state, action) => {
            return {
                ...state,
                tabValue: action.payload.tabValue
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { getHomeEvents, setTabValue } = eventSlice.actions

export default eventSlice.reducer