import { Typography } from '@mui/material';
import React from 'react';
import NotFoundImg from '../../assets/images/not-found.svg';

export function NotFound() {
    return (
        <>
            <div className='notFoundWrapper'>
                <div className='notFountData'>
                    <div className='notFoundImg'>
                        <img src={NotFoundImg} alt='' />
                    </div>
                    <Typography variant='h3'>404 Page not found</Typography>
                    <Typography variant='body1'>The page you were looking for could not be found. It might have been removed, renamed, or did not exist in the first place.</Typography>
                </div>
            </div>
        </>
    )
}

export default NotFound;
