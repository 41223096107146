import React from 'react';
import { NavLink } from 'react-router-dom';

export function Quicklinks() {
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
    };

    return (
        <>
            <div className='quickLinks'>
                <h3>Quick Links</h3>
                <ul>
                    <li>
                        <NavLink to="/" onClick={scrollToTop}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" onClick={scrollToTop}>About</NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/competitions">Competitions</NavLink>
                    </li> */}
                    <li>
                        <NavLink to="/conferences" onClick={scrollToTop}>Conferences</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" onClick={scrollToTop}>Contact</NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Quicklinks