import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Phonenumber from '../phonenumber/phonenumber';
import Socialmedia from '../socialmedia/socialmedia';
import { useDispatch } from 'react-redux';
import { getHeaderDataFailure, getHeaderDataLoading, getHeaderDataSuccess } from '../header/headerSlice';

const { REACT_APP_API_URL } = process.env;

const theme = createTheme({
    palette: {
        primary: {
            main: '#660066',
        },
        secondary: {
            main: '#050606',
        },
    },
});

export function Topbar() {

    const dispatch = useDispatch()

    useEffect(() => {
        getHeaderData();
    });

    const getHeaderData = () => {
        dispatch(getHeaderDataLoading());
        fetch(`${REACT_APP_API_URL}website-settings`, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(getHeaderDataSuccess({ websiteSettings: responseJson.data }));
                } else {
                    dispatch(getHeaderDataFailure({ error: responseJson }));
                    console.log(responseJson);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" elevation={0} className='topBar'>
                        <Container maxWidth="lg" className='containerFe'>
                            <Toolbar variant="dense" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    <ul className='topbarLinks'>
                                        <li>
                                            <Phonenumber hasIcon={false} />
                                        </li>
                                        <span className='seprator'></span>
                                        <li className='socialLinks'>
                                            <Socialmedia />
                                        </li>
                                    </ul>
                                </Typography>
                            </Toolbar>
                        </Container>
                    </AppBar>
                </Box>
            </ThemeProvider>
        </>
    )
}

export default Topbar;
