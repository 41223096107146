import { createSlice } from '@reduxjs/toolkit'

export const conferencedetailSlice = createSlice({
    name: 'conferenceDetails',
    initialState: {
        conference: {
            conferenceName: '',
            conferenceType: 1,
            conferenceDate: convertDateTimeFormat(new Date()).toString(),
            conferencePlace: '',
            deadlineDate: convertDateTimeFormat(new Date()).toString(),
            accommodationID: '',
            fullRefundBy: convertDateTimeFormat(new Date()).toString(),
            halfRefundBy: convertDateTimeFormat(new Date()).toString(),
            noRefund: convertDateTimeFormat(new Date()).toString(),
            mapCode: '',
            conferenceAddress: '',
            schedulePdfName: '',
            covidGuidelinesPdfName: '',
            feeTen: 0,
            feeNineteen: 0,
            feeTwenty: 0,
            feeNote: '',
            conferenceImage: null,
            conferenceaccommodations: {
                accommodationTitle: '',
                accommodationContent: '',
                accommodationDate: convertDateTimeFormat(new Date()).toString(),
        }
        },
        message: null,
        loading: false,
        decideLater: {
            conferenceDate: false,
            deadlineDate: false,
            fullRefundBy: false,
            halfRefundBy: false,
            noRefund: false
        },
    },
    reducers: {

        getConferenceDataById: (state, action) => {
            return {
                ...state,
                conference: state.conference,
                loading: true
            }
        },
        getConferenceDataByIdSuccess: (state, action) => {
            return {
                ...state,
                conference: {
                    id: action.payload.conference.id,
                    conferenceName: action.payload.conference.name,
                    conferenceType: action.payload.conference.type,
                    conferenceDate: action.payload.conference.conference_date ? convertDateTimeFormat2(action.payload.conference.conference_date).toString() : 'TBA',
                    conferencePlace: action.payload.conference.place,
                    deadlineDate: action.payload.conference.deadline_date ? convertDateTimeFormat2(action.payload.conference.deadline_date).toString() : 'TBA',
                    accommodationID: action.payload.conference.accommodation_id,
                    fullRefundBy: action.payload.conference.refund_full ? convertDateTimeFormat(action.payload.conference.refund_full).toString() : 'TBA',
                    halfRefundBy: action.payload.conference.refund_half ? convertDateTimeFormat(action.payload.conference.refund_half).toString() : 'TBA',
                    noRefund: action.payload.conference.refund_not ? convertDateTimeFormat(action.payload.conference.refund_not).toString() : 'TBA',
                    mapCode: action.payload.conference.map_code,
                    conferenceAddress: action.payload.conference.address,
                    schedulePdfName: action.payload.conference.schedule_pdf,
                    covidGuidelinesPdfName: action.payload.conference.covid_pdf,
                    feeTen: action.payload.conference.fee_one_to_ten,
                    feeNineteen: action.payload.conference.fee_ten_to_nineteen,
                    feeTwenty: action.payload.conference.fee_twenty_plus,
                    feeNote: action.payload.conference.fee_note,
                    conferenceImage: action.payload.conference.image,
                    conferenceaccommodations: {
                        accommodationTitle: action.payload.conference.conferenceaccommodations?.title,
                        accommodationContent: action.payload.conference.conferenceaccommodations?.content,
                        accommodationDate: action.payload.conference.conferenceaccommodations?.date ?
                            convertDateTimeFormat(action.payload.conference.conferenceaccommodations.date).toString() : 'TBA',
                    }
                },
                loading: false,
                decideLater: {
                    conferenceDate: action.payload.conference.conference_date !== null ? false : true,
                    deadlineDate: action.payload.conference.deadline_date !== null ? false : true,
                    fullRefundBy: action.payload.conference.refund_full !== null ? false : true,
                    halfRefundBy: action.payload.conference.refund_half !== null ? false : true,
                    noRefund: action.payload.conference.refund_not !== null ? false : true,
                    accommodationDate: action.payload.conference.conferenceaccommodations.date !== null ? false : true

                }
            }
        },
        getConferenceDataByIdFailure: (state, action) => {
            return {
                ...state,
                errors: [],
                loading: false
            }
        }
    }
})

function convertDateTimeFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
}

function convertDateTimeFormat2(str) {
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(str).toLocaleDateString('en-US', dateOptions);
    return date;
}

// Action creators are generated for each case reducer function
export const {
    getConferenceDataById,
    editConferenceLoading,
    getConferenceDataByIdSuccess,
    getConferenceDataByIdFailure,
} = conferencedetailSlice.actions

export default conferencedetailSlice.reducer