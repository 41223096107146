import { createSlice } from "@reduxjs/toolkit";

export const conferencesSlice = createSlice({
  name: "conferenceData",
  initialState: {
    about: {
      title: "",
      content: "",
      slider: "",
    },
    conference: [],
    mounted: false,
    error: "",
  },
  reducers: {
    getSliderData: (state, action) => {
      return {
        ...state,
        slider: action.payload,
        mounted: true,
      };
    },
    getConferenceData: (state, action) => {
      return {
        ...state,
        conference: action.payload.data,
        slider: action.payload.slider,
        mounted: true,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { getSliderData, getConferenceData } = conferencesSlice.actions;

export default conferencesSlice.reducer;
