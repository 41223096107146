
import { Button, createTheme, TextField, Box, Grid, Snackbar, IconButton } from '@mui/material';
import React, { useState } from 'react';
import validator from 'validator'
import CloseIcon from '@mui/icons-material/Close';

export function Newsletter() {
    const { REACT_APP_API_URL } = process.env;
    const theme = createTheme({
        palette: {
            primary: {
                main: '#660066',
            },
            secondary: {
                main: '#050606',
            },
        },
    });

    const [emailError, setEmailError] = useState(false)
    const [emailHelperText, setEmailHelperText] = useState('')
    const [buttonDisabled, SetButtonDisabled] = useState(false)
    const [message, setMessage] = useState('');
    const [apimessage, setapiMessage] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal } = snackbarState;

    const snackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };

    const validateEmail = (e) => {
        const email = e.target.value
        setMessage(e.target.value);
        if (validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
            SetButtonDisabled(false)
        } else {
            setEmailError(true)
            setEmailHelperText('Enter a valid email.')
            SetButtonDisabled(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.nativeEvent.target[0].value;
        if (validator.isEmail(email)) {
            setEmailError(false)
            setEmailHelperText('')
        } else {
            setEmailError(true)
            setEmailHelperText('Enter a valid email.')
        }
        if (email) {
            sendNewsLetterRequest(email);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState({ ...snackbarState, open: false });
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const sendNewsLetterRequest = (email) => {
        SetButtonDisabled(true);
        fetch(`${REACT_APP_API_URL}nlsubscriber`, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify({
                "email": email,
            })
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.response_code === 200 && responseJson.status === true) {
                    SetButtonDisabled(false);
                    setapiMessage(responseJson.message);
                    if (responseJson) {
                        setSnackbarState({ ...snackbarState, open: true });
                        resetNewsletter();
                        console.log('form done');
                    }

                } else {
                    SetButtonDisabled(false);
                    setapiMessage(responseJson.errors.email[0])
                    if (responseJson) {
                        setSnackbarState({ ...snackbarState, open: true });
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const resetNewsletter = () => {
        setMessage('');
      };

    return (
        <>
            <div className='newsletter'>
                <h2>subscribe our newsletter</h2>
                <form  id="newsletter-form" onSubmit={(e) => handleSubmit(e)}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField variant="filled" fullWidth={true} onChange={(e) => validateEmail(e)} label={'Enter Your Email...'} id='newsletter' color='secondary' size="small" className='newsletterFeild' value={message} theme={theme} error={emailError} helperText={emailHelperText} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Button type="submit" disabled={buttonDisabled} variant="contained" disableElevation theme={theme} className='formButton' color='secondary'>Subscribe Now</Button>
                            </Grid>
                            <Snackbar
                                anchorOrigin={{ vertical, horizontal }}
                                open={snackbarState.open}
                                autoHideDuration={6000}
                                onClose={snackbarClose}
                                message={apimessage}
                                action={action}
                                key={vertical + horizontal}
                            />
                        </Grid>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Newsletter
