import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/store';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  typography: {
    fontFamily: 'Mulish, sans-serif',
  },
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#580058',
    },
    secondary: {
      main: '#4e8ca7',
    },
  }
});

theme.typography.h1 = {
  fontFamily: 'Mulish, sans-serif',
};

theme.typography.h2 = {
  fontFamily: 'Mulish, sans-serif',
};

theme.typography.h3 = {
  fontFamily: 'Mulish, sans-serif',
};

theme.typography.h4 = {
  fontFamily: 'Mulish, sans-serif',
};

theme.typography.body1 = {
  fontFamily: 'Mulish, sans-serif',
};

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
