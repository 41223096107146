import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import PropTypes from 'prop-types';


export function Slideshow(props) {

    const images = props.images;

    return (

        <div className="slide-container" style={{ width: '100%' }}>
            {!!images && (
                <Fade>
                    {images?.map((slideImage, index) => (
                        < div className="each-slide" key={index} >
                            <a href={slideImage.link} rel="noreferrer" target="_blank">
                                <div className='adsSlideshowItem' style={{
                                    'backgroundImage': `url(${slideImage.image})`
                                }} >
                                </div>
                            </a>
                        </div>
                    ))}
                </Fade>
            )
            }

        </div >
    )
}

Slideshow.propTypes = {
    images: PropTypes.array
}

export default Slideshow;