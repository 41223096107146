import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';

export function Navigation() {

    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
    }
    const toggleSubNav = () => {
        setToggleMenu(!toggleMenu)
    }

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWidth)
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <div className='navigation'>
            <nav>
                {(toggleMenu || screenWidth > 767) && (
                    <ul className="list">
                        <li>
                            <NavLink className={({ isActive }) => "nav-link items" + (isActive ? " activated" : "")} to="/" onClick={toggleSubNav}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => "nav-link items" + (isActive ? " activated" : "")} to="/about" onClick={toggleSubNav}>About</NavLink>
                        </li>
                        {/* <li>
                            <NavLink className={({ isActive }) => "nav-link items" + (isActive ? " activated" : "")} to="/competitions" onClick={toggleSubNav}>Competitions</NavLink>
                        </li> */}
                        {/*<li>
                            <NavLink className={({ isActive }) => "nav-link items" + (isActive ? " activated" : "")} to="/conferences" onClick={toggleSubNav}>Conferences</NavLink>
                        </li>*/}
                        <li>
                            <NavLink className={({ isActive }) => "nav-link items" + (isActive ? " activated" : "")} to="/contact" onClick={toggleSubNav}>Contact</NavLink>
                        </li>
                    </ul>
                )}

                <button onClick={toggleNav} className="btn"><MenuIcon /></button>
            </nav>
        </div>
    )
}