import './App.scss';
import {
  BrowserRouter as Router
} from "react-router-dom";
import React from 'react';
import { Routing } from './features/routing/routing';

function App() {
  return (
    <div className="App">
      <Router>
        <Routing />
      </Router>
    </div>
  );
}

export default App;
