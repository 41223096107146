import { Grid } from '@mui/material';
import React, { useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getherobannerData, setCarousel } from '../herobanner/herobannerSlice';

const { REACT_APP_API_URL } = process.env;

export function Herobanner() {

    const carousel = useSelector(state => state?.herobanner?.carousel);
    const mounted = useSelector(state => state?.herobanner?.mounted);
    const dispatch = useDispatch()
    useEffect(() => {
        if (!mounted) {
            getBannerData();
        }
    });
    const banner = useSelector(state => state?.herobanner?.herobannerdata)

    const getBannerData = () => {
        fetch(`${REACT_APP_API_URL}fe-homepage-slider`, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(getherobannerData(responseJson.data));
                    dispatch(setCarousel(true));
                    if (responseJson.data.length === 0) {
                        let noData = document.getElementsByClassName("noSlide");
                        for (var i = 0; i < noData.length; i++) {
                            noData[i].textContent += 'Please add Slides.';
                        }
                    }
                } else {
                    console.log(responseJson);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            {!!carousel && (
                <Carousel autoPlay={true} showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={true}>
                    {banner.length > 0 ? banner.map((row) => (
                        <div key={row.id} className='bannerSlideWrapper'>
                            <div className='bannerSlide' style={{ backgroundImage: "url(" + row.image + ")" }}></div>
                            <Grid container spacing={2}>
                                <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
                                <Grid item lg={10} md={10} sm={10} xs={12} sx={{ position: 'relative' }}>
                                    <div className='legend'>
                                        <h1>{row.heading}</h1>
                                        <p>{row.description}</p>
                                    </div>
                                </Grid>
                                <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
                            </Grid>
                        </div>
                    )) : <div className='bannerSlideWrapper'><div className={'noSlide'} style={{ padding: '120px 0 0 0' }}></div></div>}
                </Carousel>
            )}
        </>
    )
}

export default Herobanner;
