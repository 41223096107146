import { createSlice } from "@reduxjs/toolkit";

export const aboutSlice = createSlice({
  name: "aboutData",
  initialState: {
    about: {
      title: "",
      content: "",
      slider: "",
    },
    mounted: false,
    error: "",
  },
  reducers: {
    getaboutData: (state, action) => {
      return {
        ...state,
        about: action.payload,
        mounted: true,
      };
    },
    getSliderData: (state, action) => {
      return {
        ...state,
        slider: action.payload,
        mounted: true,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { getaboutData, getSliderData } = aboutSlice.actions;

export default aboutSlice.reducer;
