import {
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentPageCarousel from "../contentpagecarousel/contentpagecarousel";
import { getSliderData, getConferenceData } from "./conferencesSlice";

const { REACT_APP_API_URL } = process.env;

export function Conferences() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slider = useSelector((state) => state?.conferences?.slider);
  const mounted = useSelector((state) => state?.conferences?.mounted);
  const rows = useSelector((state) => state?.conferences?.conference);
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  console.log(slider);

  useEffect(() => {
    if (!mounted) {
      // getSlider();
      conferencesData();
    }
  });

  const getSlider = () => {
    fetch(`${REACT_APP_API_URL}fe-contentpage-slider`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getSliderData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const conferencesData = () => {
    fetch(
      `${REACT_APP_API_URL}fe-conferences?pageSliderKey=CONFERENCES_ID&pageSize=5`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getConferenceData(responseJson));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container spacing={2} className="fluidContainer padLeftNone">
        <div className="eventsWrapper" style={{ paddingTop: "0" }}>
          <Grid container spacing={2}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <div className="joinOurTeamWrapper">
                {!!slider && <ContentPageCarousel images={slider} />}
              </div>
            </Grid>
            <Grid
              item
              lg={7}
              md={7}
              sm={12}
              xs={12}
              sx={{ paddingLeft: "30px" }}
            >
              <div className="conferencesContent">
                <Typography variant="h1" className="conferencesHeading">
                  Conferences
                </Typography>
                <TableContainer>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    <TableBody>
                      {rows.length > 0 ? rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            background: "#F7F7F7",
                          }}
                        >
                          <TableCell scope="row" className="dateCol">
                            <Typography variant="h3">
                              {row.conference_date
                                ? new Date(
                                    row.conference_date
                                  ).toLocaleDateString("en-US", dateOptions)
                                : "TBA"}
                            </Typography>
                          </TableCell>
                          <TableCell className="eventCol">
                            <Typography variant="h2">{row.name}</Typography>
                            <Typography variant="body1">{row.place}</Typography>
                          </TableCell>
                          <TableCell className="actionCol" align="right">
                            <Button
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                navigate(`/conferences-detail/${row.id}`)
                              }
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                        
                      )): 
                      <TableRow>
                      <TableCell scope="row" className="dateCol">
                        <div>High Pointz Conferences coming soon!</div>
                        </TableCell>
                      </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default Conferences;
