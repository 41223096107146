import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
//import contactImage from "../../assets/images/contact-image.png";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import validator from "validator";
import Phonenumber from "../phonenumber/phonenumber";
import { useDispatch, useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { getDepartments } from "./contactSlice";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { TextMaskCustom } from "./textmaskcustom";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "capitalize",
    color: "#919191",
    fontFamily: ['"Mulish", sans-serif !important'],
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #DFDFDF",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ['"Mulish", sans-serif !important'].join(","),
    "&:focus": {
      border: "1px solid #E8E8E8",
      boxShadow: "0px 4px 20px rgba(128, 75, 128, 0.2)",
    },
  },
}));

export function Contact() {
  const { REACT_APP_API_URL } = process.env;
  const address = useSelector((state) => state.header.websiteSettings.address);
  const heading = useSelector((state) => state.header.websiteSettings.heading);
  const contactImage = useSelector(
    (state) => state.header.websiteSettings.contact_us_image
  );
  const departmentsMounted = useSelector(
    (state) => state?.departments?.departmentsMounted
  );
  const departmentsList = useSelector(
    (state) => state?.departments?.departments
  );
  const [buttonDisabled, SetButtonDisabled] = useState(false);
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [values, setValues] = useState({
    textmask: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!departmentsMounted) {
      getDepartmentData();
    }
  });

  const getDepartmentData = () => {
    fetch(`${REACT_APP_API_URL}departments`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getDepartments({ departments: responseJson.data }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectDepartment, setSelectDepartment] = useState("");
  const [selectDepartmentError, setSelectDepartmentError] = useState();
  const [selectDeaprtmentHelperText, setSelectDepartmentHelperText] =
    useState();
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameHelperText, setFirstNameHelperText] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameHelperText, setLastNameHelperText] = useState("");
  const [contactNumberError, setContactNumberError] = useState(false);
  const [contactNumberHelperText, setContactNumberHelperText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [messageHelperText, setMessageHelperText] = useState("");
  const [apimessage, setapiMessage] = useState("");

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarState;
  const snackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const verifyDepartment = (e) => {
    validateSelectDepartment(e.target.value);
  };

  const validateSelectDepartment = (selectDepartment) => {
    if (selectDepartment !== "") {
      setSelectDepartment(selectDepartment);
      setSelectDepartmentError(false);
      setSelectDepartmentHelperText("");
      return false;
    } else {
      setSelectDepartmentError(true);
      setSelectDepartmentHelperText("Select Department.");
      return true;
    }
  };

  const validateFirstName = (firstName) => {
    if (firstName !== "") {
      setFirstNameError(false);
      setFirstNameHelperText("");
      return false;
    } else {
      setFirstNameError(true);
      setFirstNameHelperText("Enter First Name.");
      return true;
    }
  };

  const validateLastName = (lastName) => {
    if (lastName !== "") {
      setLastNameError(false);
      setLastNameHelperText("");
      return false;
    } else {
      setLastNameError(true);
      setLastNameHelperText("Enter Last Name.");
      return true;
    }
  };

  const onChangeValidatePhoneNumber = (e) => {
    const contactNumber = e.target.value;
    validateContactNumber(contactNumber);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const validateContactNumber = (newContactNumber) => {
    const contactNumber = newContactNumber.replace(/[^\d\+]/g, "");
    if (!contactNumber) {
      setContactNumberError(true);
      setContactNumberHelperText("Enter Phone Number.");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length >= 10 &&
      contactNumber.length <= 15
    ) {
      setContactNumberError(false);
      setContactNumberHelperText("");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length > 15
    ) {
      setContactNumberError(true);
      setContactNumberHelperText("Enter maximum 15 digits.");
    } else if (
      contactNumber &&
      validator.isNumeric(contactNumber) &&
      contactNumber.length < 10
    ) {
      setContactNumberError(true);
      setContactNumberHelperText("Enter 10 digit phone number");
    } else {
      setContactNumberError(true);
      setContactNumberHelperText("Enter Numeric values");
    }
  };

  const verifyEmail = (e) => {
    const email = e.target.value;
    validateEmail(email);
  };

  const validateEmail = (email) => {
    if (!email) {
      setEmailError(true);
      setEmailHelperText("Email is required.");
      return true;
    } else if (email && validator.isEmail(email)) {
      setEmailError(false);
      setEmailHelperText("");
      return false;
    } else {
      setEmailError(true);
      setEmailHelperText("Email is incorrect, Enter a valid email.");
      return true;
    }
  };

  const verifyMessage = (e) => {
    const message = e.target.value;
    validateMessage(message);
  };

  const validateMessage = (message) => {
    recalculate(message);
    if (message !== "") {
      setMessageError(false);
      setMessageHelperText("");
      return false;
    } else {
      setMessageError(true);
      setMessageHelperText("Enter Message.");
      return true;
    }
  };

  const recalculate = (message) => {
    console.log("event value:", message);
    setTextAreaCount(message.length);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    let checkError = [];
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
    const formData = {
      selectDepartment: removeExtraSpace(e.nativeEvent.target[0].value),
      firstName: removeExtraSpace(e.nativeEvent.target[1].value),
      lastName: removeExtraSpace(e.nativeEvent.target[2].value),
      contactNumber: removeExtraSpace(
        e.nativeEvent.target[3].value.replace(/[^\d\+]/g, "")
      ),
      email: removeExtraSpace(e.nativeEvent.target[4].value),
      message: removeExtraSpace(e.nativeEvent.target[5].value),
    };

    const selectDepartmentErrors = validateSelectDepartment(
      formData.selectDepartment
    );
    const firstNameErrors = validateFirstName(formData.firstName);
    const lastNameErrors = validateLastName(formData.lastName);
    const contactNumberErrors = validateContactNumber(formData.contactNumber);
    const emailErrors = validateEmail(formData.email);
    const messageErrors = validateMessage(formData.message);

    checkError.push(
      selectDepartmentErrors,
      firstNameErrors,
      lastNameErrors,
      contactNumberErrors,
      emailErrors,
      messageErrors
    );

    if (!checkError.includes(true)) {
      sendContactRequest(formData);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sendContactRequest = (formData) => {
    SetButtonDisabled(true);
    fetch(`${REACT_APP_API_URL}contact-form`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        department_id: formData.selectDepartment,
        first_name: formData.firstName,
        last_name: formData.lastName,
        contact: formData.contactNumber,
        email: formData.email,
        message: formData.message,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.response_code === 200 &&
          responseJson.status === true
        ) {
          SetButtonDisabled(false);
          setapiMessage(responseJson.message);
          if (responseJson) {
            setTextAreaCount(0);
            setSnackbarState({ ...snackbarState, open: true });
            cancelContact();
          }
        } else {
          SetButtonDisabled(false);
          setapiMessage(responseJson.errors);
          if (responseJson) {
            setSnackbarState({ ...snackbarState, open: true });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelContact = () => {
    document.getElementById("contact-form").reset();
    setSelectDepartment("");
  };

  return (
    <>
      <Container className="fluidContainer padLeftNone">
        <div className="contactWrapper">
          <Grid container spacing={2}>
            <Grid
              item
              lg={3}
              md={3}
              sm={12}
              xs={12}
              className="contactImageWrapper"
            >
              <img src={contactImage} alt="" className="contactImg" />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="contactFormWraper">
                <form id="contact-form" onSubmit={(e) => handleSubmit(e)}>
                  <Grid item container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h1">Contact</Typography>
                      <Typography
                        variant="body1"
                        component="div"
                        className="contactformSelect"
                      >
                        <span className="text">Send a message to:</span>
                        <span className="selectWrapper">
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ width: "100%" }}
                          >
                            <InputLabel
                              htmlFor="selectDepartment"
                              className="customLabel"
                            >
                              Select Department
                            </InputLabel>
                            <Select
                              labelId="selectDepartment"
                              id="selectDepartment"
                              value={selectDepartment}
                              label="department"
                              input={
                                <BootstrapInput
                                  inputProps={{
                                    id: "department",
                                  }}
                                />
                              }
                              className="bootstrapFields selectMenu"
                              error={selectDepartmentError}
                              onChange={(e) => verifyDepartment(e)}
                            >
                              {departmentsList?.map((option) => {
                                return (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.department_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {!!selectDepartmentError && (
                              <FormHelperText error id="department">
                                {selectDeaprtmentHelperText}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        variant="standard"
                        className="contactFields"
                        fullWidth
                      >
                        <InputLabel
                          shrink
                          htmlFor="firstname"
                          className="inputLabel"
                        >
                          First name*
                        </InputLabel>
                        <BootstrapInput
                          inputProps={{
                            maxLength: 45,
                          }}
                          id="firstname"
                          className="bootstrapFields"
                          error={firstNameError}
                          onChange={(e) => validateFirstName(e)}
                        />
                        {!!firstNameError && (
                          <FormHelperText error id="firstname">
                            {firstNameHelperText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        variant="standard"
                        className="contactFields"
                        fullWidth
                      >
                        <InputLabel
                          shrink
                          htmlFor="lastName"
                          className="inputLabel"
                        >
                          Last name*
                        </InputLabel>
                        <BootstrapInput
                          inputProps={{
                            maxLength: 45,
                          }}
                          id="lastName"
                          className="bootstrapFields"
                          error={lastNameError}
                          onChange={(e) => validateLastName(e)}
                        />
                        {!!lastNameError && (
                          <FormHelperText error id="lastname">
                            {lastNameHelperText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        variant="standard"
                        className="contactFields"
                        fullWidth
                      >
                        <InputLabel
                          shrink
                          htmlFor="contactNumber"
                          className="inputLabel"
                        >
                          Contact Number*
                        </InputLabel>
                        <BootstrapInput
                          value={values.textmask}
                          id="contactNumber"
                          name="textmask"
                          className="bootstrapFields"
                          error={contactNumberError}
                          onChange={(e) => onChangeValidatePhoneNumber(e)}
                          inputComponent={TextMaskCustom}
                        />
                        {!!contactNumberError && (
                          <FormHelperText error id="contactnumber">
                            {contactNumberHelperText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        variant="standard"
                        className="contactFields"
                        fullWidth
                      >
                        <InputLabel
                          shrink
                          htmlFor="emailAddress"
                          className="inputLabel"
                        >
                          Email Address*
                        </InputLabel>
                        <BootstrapInput
                          id="emailAddress"
                          className="bootstrapFields"
                          error={emailError}
                          onChange={(e) => verifyEmail(e)}
                        />
                      </FormControl>
                      {!!emailError && (
                        <FormHelperText error id="emailaddress">
                          {emailHelperText}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl
                        variant="standard"
                        className="contactFields"
                        fullWidth
                      >
                        <InputLabel
                          shrink
                          htmlFor="message"
                          className="inputLabel"
                        >
                          Message*
                        </InputLabel>
                        <TextareaAutosize
                          maxLength={500}
                          id="message"
                          className="bootstrapFields"
                          error={messageError?.toString()}
                          onChange={(e) => verifyMessage(e)}
                        />
                        <label className="inputLabel">
                          {" "}
                          {`${textAreaCount}/500`}{" "}
                        </label>
                        {!!messageError && (
                          <FormHelperText error id="message">
                            {messageHelperText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Button
                        disabled={buttonDisabled}
                        variant="contained"
                        disableElevation
                        type="submit"
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={snackbarClose}
                action={action}
                key={vertical + horizontal}
              >
                <Alert severity="success" variant="filled">
                  {apimessage}
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <div className="ruleQuestion">
                <Typography variant="h3">Rules Questions</Typography>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => navigate("/rules-and-questions")}
                >
                  Click Here
                </Button>
              </div>
              <div className="contactRight">
                <address>
                  <ul>
                    <li>
                      <span className="icon">
                        <LocationOnIcon />
                      </span>
                      <div>
                        <Typography variant="h4">{heading}</Typography>
                        <Typography variant="body1">{address}</Typography>
                      </div>
                    </li>
                    <li>
                      <Phonenumber hasIcon={true} />
                    </li>
                  </ul>
                </address>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default Contact;
