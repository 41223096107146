import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import { Button, Drawer, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEvent } from './events.function';
import { drawerToggle } from './eventdetailSlice';
import CloseIcon from '@mui/icons-material/Close';
const drawerWidth = 550;

export function Eventdetail() {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [eventId, saveEventId] = useState(0);
    const event = useSelector((state) => state.eventDetails.event);
    const sidebarState = useSelector((state) => state.eventDetails.drawerToggle)

    useEffect(() => {
        if (params) {
            const eventId = params.id;
            saveEventId(eventId);
            dispatch(getEvent(eventId));
        }
    }, [dispatch, params]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    const navigateToAccommodation = (eventId) => {
        scrollToTop();
        navigate(`/event/${eventId}/accommodation`);
    };


    return (
        <div className={'event' + eventId}>
            <Container className='containerFe'>
                <div className='eventDetailsWrapper'>
                    <div className='eventDetailsContent'>
                        <Grid container spacing={4} className='eventDetails'>
                            <Grid item lg={4} md={5} sm={6} xs={12}>
                                <div className='eventDetailsImg'>
                                    <img src={event.eventImage} alt='' />
                                </div>
                            </Grid>
                            <Grid item lg={8} md={7} sm={6} xs={12}>
                                <div className='eventDetailsTopContent'>
                                    <Typography variant='h3'>{event.eventName}</Typography>
                                    <Typography variant='h4'>{event.eventDate}</Typography>
                                    <Typography variant='h4'>{event.eventPlace}</Typography>
                                    <ul>
                                        <li>Register on time by: {event.registerOnTime}</li>
                                        <li>Final late payment by: {event.finalLatePaymentBy}</li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className='tabLinks'>
                                    <ul>
                                        <li>
                                            <Button variant="contained"
                                                onClick={() => window.open(event.divisionLevelPdfName, '_blank')}
                                            >Divisions, levels, rules &amp; scoring</Button>
                                        </li>
                                        <li>
                                            <Button variant="contained" onClick={() => navigate('/rules-and-questions')}> rules Questions</Button>
                                        </li>
                                        <li open={sidebarState}>
                                            <Button variant="contained" className='blackButton' onClick={(e) => dispatch(drawerToggle(!sidebarState))}>Awards &amp; Giveaways</Button>
                                        </li>
                                        <li>
                                            <Button variant="contained">Register</Button>
                                        </li>
                                        <li>
                                            <Button variant="contained"
                                                onClick={() => window.open(event.qualificationInvitePdfName, '_blank')}
                                                className='greenButton'>invite</Button>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant='body1' className='eventContent'>Event open to:
                                    {event.eventOpenTo === 0 ? ' Youth Squads ' : ''}
                                    {event.eventOpenTo === 1 ? ' School Squads ' : ''}
                                    {event.eventOpenTo === 2 ? ' Youth & School Squads ' : ''}
                                    | Qualifier for National?
                                    {event.qualifierForNationals === 0 ? ' No ' : ''}
                                    {event.qualifierForNationals === 1 ? ' Yes ' : ''}
                                    | <span onClick={() => window.open(event.resultsPdfName, '_blank')}
                                    >Covid Guidelines</span></Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item lg={6} md={12} sm={6} xs={12}>
                                        <div className='boxContentWrapper'>
                                            <div className='boxHeading'>
                                                <Typography variant='h4'>Youth Rates</Typography>
                                                <Typography variant='body1'>(per participant)</Typography>
                                            </div>
                                            <div className='boxContent'>
                                                <ul>
                                                    <li>On Time: {event.onTime !== 0 ? '$' + event.onTime : 'Free'}</li>
                                                    <li>Late: {event.late !== 0 ? '$' + event.late : 'Free'}</li>
                                                    <li>Crosss Competitor: {event.crossCompetitor !== 0 ? '$' + event.crossCompetitor : 'Free'}</li>
                                                    <li>2nd Performance: {event.secondPerformance !== 0 ? '$' + event.secondPerformance : 'Free'}</li>
                                                    <li className='label'>(per performance)</li>
                                                </ul>
                                                <div className='boxSpacer'></div>
                                                <Typography variant='body1'>{event.note}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={12} sm={6} xs={12}>
                                        <div className='boxContentWrapper'>
                                            <div className='boxHeading'>
                                                <Typography variant='h4'>Spectator Rates</Typography>
                                                <Typography variant='body1'>(per person)</Typography>
                                            </div>
                                            <div className='boxContent'>
                                                <ul>
                                                    <li>General: {event.general !== 0 ? '$' + event.general : 'Free'}</li>
                                                    <li>Senior 65+: {event.senior !== 0 ? '$' + event.senior : 'Free'}</li>
                                                    <li>Child 6-15: {event.child !== 0 ? '$' + event.child : 'Free'}</li>
                                                    <li>Child 5 &amp; Under: {event.childFiveUnder !== 0 ? '$' + event.childFiveUnder : 'Free'}</li>
                                                </ul>
                                                <div className='boxSpacer'></div>
                                                <ul>
                                                    <li>Parking</li>
                                                    <li style={{ textTransform: 'capitalize' }}>{event.parking}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item lg={6} md={12} sm={6} xs={12}>
                                        <div className='boxContentWrapper'>
                                            <div className='boxHeading'>
                                                <Typography variant='h4'>Refund Rates</Typography>
                                                <Typography variant='body1'>(if requested by the deadline shown)</Typography>
                                            </div>
                                            <div className='boxContent'>
                                                <ul>
                                                    <li>100% Refund by: {event.fullRefundBy}</li>
                                                    <li>50% Refund by: {event.halfRefundBy}</li>
                                                    <li>No Refund after: {event.noRefund}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={12} sm={6} xs={12}>
                                        <div className='boxContentWrapper'>
                                            <div className='boxHeading'>
                                                <Typography variant='h4'>Performance<br />Surface</Typography>
                                            </div>
                                            <div className='boxContent'>
                                                <ul>
                                                    <li>{event.performanceSurface}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div className='listingContent'>
                                            <ul>
                                                <li><strong>Preliminary Lineup</strong> {event.preliminaryLineup}</li>
                                                <li><span>Draft Schedule</span> {event.draftSchedule}</li>
                                                <li><span>Final Schedule</span> {event.finalSchedule}</li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <div className='googleMap' dangerouslySetInnerHTML={{ __html: event.mapUrl }}></div>
                            </Grid>
                            <Grid item lg={4} md={5} sm={6} xs={12}>
                                <div className='eventAddress'>
                                    <Typography variant='h3'>Event Address</Typography>
                                    <Typography variant='body1'>{event.eventAddress}</Typography>
                                    <ul className='halfButton'>
                                        <li>
                                            <Button variant='contained' className='blackButton'
                                                onClick={() => window.open(event.schedulePdfName, '_blank')}
                                            >Schedule</Button>
                                        </li>
                                        <li>
                                            <Button variant='contained' className='blackButton'
                                                onClick={() => window.open(event.resultsPdfName, '_blank')}
                                            >Results</Button>
                                        </li>
                                    </ul>
                                    <ul className='fullButton'>
                                        <li>
                                            <Button variant='contained' onClick={() => navigateToAccommodation(`${eventId}`)}>Accommodations</Button>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
            <Drawer
                sx={{
                    width: '100%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        maxWidth: drawerWidth,
                        boxSizing: 'border-box'
                    },
                }}
                open={sidebarState}
                anchor="right"
                onClose={(e) => dispatch(drawerToggle(!sidebarState))}
            >
                <div className='drawerWrapper'>
                    <div className='closeButton'>
                        <IconButton variant="contained" color="primary" onClick={(e) => dispatch(drawerToggle(!sidebarState))}><CloseIcon /></IconButton>
                    </div>
                    <div className='drawerContent'>
                        <Typography variant='h2'>Awards &amp; Giveaways</Typography>
                        <Typography variant='body1' component={'div'} className="awardsContent"
                            dangerouslySetInnerHTML={{ __html: event.awardGiveaway }}></Typography>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Eventdetail;
