import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getprivacypolicyData } from "./privacypolicySlice";

const { REACT_APP_API_URL } = process.env;

export function Privacypolicy() {

  const mounted = useSelector((state) => state?.privacypolicyData?.mounted);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!mounted) {
      getPrivacyPolicy();
    }
  });
  const getprivacypolicyContent = useSelector(
    (state) => state.privacypolicyData.privacypolicy
  );

  const getPrivacyPolicy = () => {
    fetch(
      `${REACT_APP_API_URL}page/detail/PRIVACY_POLICY_ID/PRIVACY_POLICY`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getprivacypolicyData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container spacing={0} className='containerFe'>
        <Grid item xs={12} sx={{ margin: '0 -15px' }}>
          <div className='privacyPolicyWrapper'>
            <Typography variant='h1' >{getprivacypolicyContent.title}</Typography>
            <div className="ckeditor_content_render" dangerouslySetInnerHTML={{ __html: getprivacypolicyContent.content }}></div>
          </div>
        </Grid>
      </Container>
    </>
  )
}

export default Privacypolicy;
