import { createSlice } from '@reduxjs/toolkit'

export const contentbannerSlice = createSlice({
    name: 'contentbanner',
    initialState: {
        contentbannerdata: {
            slider: "",
        },
        mounted: false,
        error: ''
    },
    reducers: {
        getSliderData: (state, action) => {
            return {
              ...state,
              slider: action.payload,
              mounted: true,
            };
          },
    }
})

// Action creators are generated for each case reducer function
export const { getSliderData } = contentbannerSlice.actions

export default contentbannerSlice.reducer