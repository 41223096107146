import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getadvertisementData } from '../advertisementblocks/advertisementSlice';
import { useDispatch, useSelector } from 'react-redux';
import Slideshow from './slideshow';

const { REACT_APP_API_URL } = process.env;

export function Advertisementblocks() {
    const mounted = useSelector(state => state?.advertisementdata?.mounted);
    const [leftAdvertCount, setLeftAdvertCount] = useState(0)
    const [rightAdvertCount, setRightAdvertCount] = useState(0)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!mounted) {
            getAdvertisementData();
        }
        advertisement[0] ? setLeftAdvertCount(Object.values(advertisement[0])?.length) : setLeftAdvertCount(0);
        advertisement[1] ? setRightAdvertCount(Object.values(advertisement[1])?.length) : setRightAdvertCount(0);
    });
    const advertisement = useSelector(state => state.advertisementdata.advertisement)

    const getAdvertisementData = () => {
        fetch(`${REACT_APP_API_URL}fe-advertisements`, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === true) {
                    dispatch(getadvertisementData(responseJson.data));
                } else {
                    console.log(responseJson);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        <>
            <Container className='containerFe'>
                <Grid container spacing={4} className='advertisement' sx={{ marginTop: '0' }}>
                    {(Object.values(advertisement).length > 1) ?
                        <Grid item lg={6} md={6} sm={6} xs={12}>

                            {leftAdvertCount === 1 ? (
                                <a href={advertisement[0][1]?.link}>
                                    <div className='advertisementImage' style={{ backgroundImage: `url(${advertisement[0][1]?.image})` }}></div>
                                </a>
                            ) : ''}

                            {leftAdvertCount > 1 ? <Slideshow images={Object.values(advertisement[0])} /> : ''}
                        </Grid>
                        : ''}
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        {rightAdvertCount === 1 ? (
                            <a href={advertisement[1][0]?.link}>
                                <div className='advertisementImage' style={{ backgroundImage: `url(${advertisement[1][0]?.image})` }}></div>
                            </a>
                        ) : ''}

                        {rightAdvertCount > 1 ? <Slideshow images={Object.values(advertisement[1])} /> : ''}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Advertisementblocks;
