import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import headerReducer from '../features/header/headerSlice'
import eventReducer from '../features/events/eventSlice'
import departmentReducer from '../features/contact/contactSlice'
import advertisementReducer from '../features/advertisementblocks/advertisementSlice'
import herobannerReducer from '../features/herobanner/herobannerSlice'
import eventdetailSlice from '../features/eventdetail/eventdetailSlice'
import competitionguidelinesReducer from '../features/competitionguidelines/competitionguidelinesSlice'
import aboutReducer from '../features/about/aboutSlice'
import privacypolicyReducer from '../features/privacypolicy/privacypolicySlice'
import rulesandquestionsReducer from '../features/rulesandquestions/rulesandquestionsSlice'
import conferencesReducer from '../features/conferences/conferencesSlice'
import contentbannerReducer from '../features/contentpagecarousel/contentpagecarouselSlice'
import conferencesDetailReducer from '../features/conferencesdetail/conferencedetailSlice'


export default configureStore({
    reducer: {
        counter: counterReducer,
        header: headerReducer,
        homeEvents: eventReducer,
        eventDetails: eventdetailSlice,
        departments: departmentReducer,
        advertisementdata: advertisementReducer,
        herobanner: herobannerReducer,
        competitionguidelinesData: competitionguidelinesReducer,
        aboutData: aboutReducer,
        privacypolicyData: privacypolicyReducer,
        rulesandquestionsData: rulesandquestionsReducer,
        conferences: conferencesReducer,
        contentbanner: contentbannerReducer,
        conferenceDetails: conferencesDetailReducer,
    }
})