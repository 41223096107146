import { Card, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';
import partnerIcon from '../../assets/images/partner-icon.svg';
import vendorIcon from '../../assets/images/vender-icon.svg';
import joinOurTeamIcon from '../../assets/images/join-our-team-icon.svg';
import judgesIcon from '../../assets/images/judges-icon.svg';

const Ctacard = () => {

    const ctaCardInfo = [
        { icon: partnerIcon, title: 'Partners', text: 'Are you interested in becoming a partner with high pointz?', button: 'Learn More', linkRef: '/partners', id: 1 },
        { icon: vendorIcon, title: 'Vendors', text: 'Are you interested in becoming a Vendor for high pointz?', button: 'Learn More', linkRef: '/vendors', id: 2 },
        { icon: joinOurTeamIcon, title: 'Join Our Team', text: 'Are you interested in joining high pointz’s staff?', button: 'Learn More', linkRef: '/Join-our-team', id: 3 },
        { icon: judgesIcon, title: 'Judges', text: 'Are you interested in becoming a judge with  high pointz?', button: 'Learn More', linkRef: '/judges', id: 4 },
    ]

    const renderCard = (card) => {
        return (
            <Card key={card.id} className='coxOuter'>
                <CardContent className='ctaCardContent'>
                    <img src={card.icon} alt='' />
                    <Typography variant="h2" sx={{ mb: 1.5 }}>
                        {card.title}
                    </Typography>
                    <Typography variant="body1">
                        {card.text}
                    </Typography>
                </CardContent>
                <CardActions className='cardActions'>
                    <a href={card.linkRef}>{card.button}</a>
                </CardActions>
            </Card>
        )
    }

    return (
        <>
            {ctaCardInfo.map(renderCard)}
        </>
    )
};

export default Ctacard;
