import React from 'react';
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import Advertisementblocks from '../advertisementblocks/advertisementblocks';
import { Counter } from '../counter/counter';
import Events from '../events/events';
import Footer from '../footer/footer';
import Header from '../header/header';
import Homepagecta from '../homepagecta/homepagecta';
import Herobanner from '../herobanner/herobanner';
import Topbar from '../topbar/topbar';
import JoinOurTeam from '../joinourteam/joinourteam';
import CompletionGuildlines from '../competitionguidelines/competitionguidelines';
import Eventdetail from '../eventdetail/eventdetail';
import Partners from '../partners/partners';
import Vendors from '../vendors/vendors';
import Contact from '../contact/contact';
import Judges from '../judges/judges';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import About from '../about/about';
import Rulesandquestions from '../rulesandquestions/rulesandquestions';
import Privacypolicy from '../privacypolicy/privacypolicy';
import Conferences from '../conferences/conferences';
import Accommodation from '../accomodation/accommodation';
import ConferenceAccomodation from '../conference-accomodation/accommodation';
import NotFound from '../notfound/notfound';
import ConferencesDetail from '../conferencesdetail/conferencesdetail';

export function Routing() {
    return (
        <React.Fragment>
            <Topbar />
            <Header />
            <Routes>
                <Route exact path="/" element={<Home />}>
                </Route>
                <Route exact path="/counter" element={<CounterComponent />}>
                </Route>
                <Route path="/about" element={<AboutComponent />}>
                </Route>
                <Route path="/competitions" element={<Competitions />}>
                </Route>
                <Route path="/conferences" element={<ConferencesComponent />}>
                </Route>
                <Route path="/conferences-detail/:id" element={<ConferencesDetailComponent />}>
                </Route>
                <Route path="/event/:id" element={<EventdetailComponent />}>
                </Route>
                <Route path="/event/:id/accommodation" element={<AccommodationComponent />}>
                </Route>
                <Route path="/conference/:id/accommodation" element={<ConferenceAccommodationComponent />}>
                </Route>
                <Route path="/contact" element={<ContactComponent />}>
                </Route>
                <Route path="/privacy-policy" element={<PrivacypolicyComponent />}>
                </Route>
                <Route path="/join-our-team" element={<Joinourteam />}>
                </Route>
                <Route path="/partners" element={<PartnersComponent />}>
                </Route>
                <Route path="/vendors" element={<VendorsComponent />}>
                </Route>
                <Route path="/judges" element={<JudgesComponent />}>
                </Route>
                <Route path="/competition-guidelines" element={<Competitionguidelines />}>
                </Route>
                <Route path="/rules-and-questions" element={<RulesandquestionsComponent />}>
                </Route>
                <Route path="*" element={<NotFoundComponent />} />
            </Routes>
            <Footer />
        </React.Fragment >
    )
}

function CounterComponent() {
    return (
        <div>
            <Counter />
        </div>
    );
}

function Home() {
    const loading = useSelector(state => state.header.loading);
    return (
        <div>
            <Herobanner />
            <Events />
            <Homepagecta />
            <Advertisementblocks />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

function AboutComponent() {
    return (
        <div>
            <About />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function Competitions() {
    return (
        <div>
            <h2>Competitions</h2>
        </div>
    );
}

function ConferencesComponent() {
    return (
        <div>
            <Conferences />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function ConferencesDetailComponent() {
    return (
        <div>
            <ConferencesDetail />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function EventdetailComponent() {
    return (
        <div>
            <Eventdetail />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function ContactComponent() {
    return (
        <div>
            <Contact />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function PrivacypolicyComponent() {
    return (
        <div>
            <Privacypolicy />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function Joinourteam() {
    return (
        <div>
            <JoinOurTeam />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function PartnersComponent() {
    return (
        <div>
            <Partners />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function VendorsComponent() {
    return (
        <div>
            <Vendors />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function Competitionguidelines() {
    return (
        <div>
            <CompletionGuildlines />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    )
}

function JudgesComponent() {
    return (
        <div>
            <Judges />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    );
}

function RulesandquestionsComponent() {
    return (
        <div>
            <Rulesandquestions />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    )
}

function AccommodationComponent() {
    return (
        <div>
            <Accommodation />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    )
}
function ConferenceAccommodationComponent() {
    return (
        <div>
            <ConferenceAccomodation />
            <Homepagecta />
            <Advertisementblocks />
        </div>
    )
}

function NotFoundComponent() {
    return (
        <div>
            <NotFound />
        </div>
    );
}
