import { getEventDataById, getEventDataByIdFailure, getEventDataByIdSuccess } from "./eventdetailSlice";


const { REACT_APP_API_URL } = process.env;

export function getEvent(id) {
    return function getEventByIdThunk(dispatch) {
        dispatch(getEventDataById());
        fetch(`${REACT_APP_API_URL}event/detail/${id}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson) {
                    dispatch(getEventDataByIdSuccess({ event: responseJson.data }));
                } else {
                    dispatch(getEventDataByIdFailure({ error: "API Error" }));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(getEventDataByIdFailure({ error: err }));
            });
    };
}