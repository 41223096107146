import React from "react";
import Phonenumber from "../phonenumber/phonenumber";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

export function Getintouch() {
  const emailAddress = useSelector(
    (state) => state.header.websiteSettings.email
  );
  const address = useSelector((state) => state.header.websiteSettings.address);
  const heading = useSelector((state) => state.header.websiteSettings.heading);

  return (
    <>
      <div className="getItTouch">
        <h3>Get In touch</h3>
        <address>
          <ul>
            <li>
              <span className="icon">
                <LocationOnIcon />
              </span>
              <div>
                <Typography variant="h4">{heading}</Typography>
                <p>{address}</p>
              </div>
            </li>
            <li>
              <Phonenumber hasIcon={true} />
            </li>
            <li>
              <span className="icon">
                <EmailIcon />
              </span>
              <div>
                <a href={"mailto:" + emailAddress}>{emailAddress}</a>
              </div>
            </li>
          </ul>
        </address>
      </div>
    </>
  );
}

export default Getintouch;
