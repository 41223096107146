import { createSlice } from '@reduxjs/toolkit'

export const privacypolicySlice = createSlice({
    name: 'privacypolicyData',
    initialState: {
        privacypolicy: {
            title: '',
            content: "",
        },
        mounted: false,
        error: ''
    },
    reducers: {
        getprivacypolicyData: (state, action) => {
            return {
                ...state,
                privacypolicy: action.payload,
                mounted: true,
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { getprivacypolicyData } = privacypolicySlice.actions

export default privacypolicySlice.reducer