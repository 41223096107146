import { createSlice } from '@reduxjs/toolkit'

export const eventdetailSlice = createSlice({
    name: 'eventDetails',
    initialState: {
        event: {
            eventName: '',
            eventType: 1,
            eventDate: convertDateTimeFormat(new Date()).toString(),
            eventPlace: '',
            registerOnTime: convertDateTimeFormat(new Date()).toString(),
            finalLatePaymentBy: convertDateTimeFormat(new Date()).toString(),
            ruleQuestion: '',
            eventOpenTo: 0,
            qualifierForNationals: 1,
            performanceSurface: '',
            preliminaryLineup: '',
            shortDescription: '',
            draftSchedule: '',
            finalSchedule: '',
            onTime: 0,
            late: 0,
            crossCompetitor: 0,
            secondPerformance: 0,
            note: '',
            general: 0,
            senior: 0,
            child: 0,
            childFiveUnder: 0,
            parking: 'yes',
            fullRefundBy: convertDateTimeFormat(new Date()).toString(),
            halfRefundBy: convertDateTimeFormat(new Date()).toString(),
            noRefund: convertDateTimeFormat(new Date()).toString(),
            mapUrl: '',
            eventAddress: '',
            pictureOfFacility: '',
            divisionLevelPdfName: '',
            schedulePdfName: '',
            resultsPdfName: '',
            covidGuidelinesPdfName: '',
            qualificationInvitePdfName: '',
            eventImage: null,
            accommodation: {
                accommodationTitle: '',
                accommodationContent: '',
                accommodationDate: convertDateTimeFormat(new Date()).toString(),
            }
        },
        message: null,
        loading: false,
        decideLater: {
            eventDate: false,
            registerOnTime: false,
            finalLatePaymentBy: false,
            fullRefundBy: false,
            halfRefundBy: false,
            noRefund: false
        },
        drawerToggle: false
    },
    reducers: {

        getEventDataById: (state, action) => {
            return {
                ...state,
                event: state.event,
                loading: true
            }
        },
        getEventDataByIdSuccess: (state, action) => {
            return {
                ...state,
                event: {
                    id: action.payload.event.id,
                    eventName: action.payload.event.name,
                    eventType: action.payload.event.type,
                    eventDate: action.payload.event.event_date ? convertDateTimeFormat2(action.payload.event.event_date).toString() : 'TBA',
                    eventPlace: action.payload.event.place,
                    registerOnTime: action.payload.event.register_by ? convertDateTimeFormat(action.payload.event.register_by).toString() : 'TBA',
                    finalLatePaymentBy: action.payload.event.final_payment_by ? convertDateTimeFormat(action.payload.event.final_payment_by).toString() : 'TBA',
                    ruleQuestion: action.payload.event.rules_question,
                    eventOpenTo: action.payload.event.open_to,
                    qualifierForNationals: action.payload.event.qualifier,
                    performanceSurface: action.payload.event.performance_surface,
                    preliminaryLineup: action.payload.event.preliminary_lineup,
                    shortDescription: action.payload.event.preliminary_lineup,
                    draftSchedule: action.payload.event.draft_schedule,
                    finalSchedule: action.payload.event.final_schedule,
                    awardGiveaway: action.payload.event.award_giveaway,
                    onTime: action.payload.event.youth_ontime,
                    late: action.payload.event.youth_late,
                    crossCompetitor: action.payload.event.youth_crosscompetitor,
                    secondPerformance: action.payload.event.youth_2ndperformance,
                    note: action.payload.event.youth_note,
                    general: action.payload.event.spector_general,
                    senior: action.payload.event.spector_senior,
                    child: action.payload.event.spector_child,
                    childFiveUnder: action.payload.event.spector_childunder5,
                    parking: action.payload.event.spector_parking,
                    fullRefundBy: action.payload.event.fullRefundBy ? convertDateTimeFormat(action.payload.event.refund_full).toString() : 'TBA',
                    halfRefundBy: action.payload.event.halfRefundBy ? convertDateTimeFormat(action.payload.event.refund_half).toString() : 'TBA',
                    noRefund: action.payload.event.noRefund ? convertDateTimeFormat(action.payload.event.refund_not).toString() : 'TBA',
                    mapUrl: action.payload.event.map_url,
                    eventAddress: action.payload.event.address,
                    divisionLevelPdfName: action.payload.event.dlrs_pdf,
                    schedulePdfName: action.payload.event.schedule_pdf,
                    resultsPdfName: action.payload.event.result_pdf,
                    covidGuidelinesPdfName: action.payload.event.covid_pdf,
                    qualificationInvitePdfName: action.payload.event.invite_pdf,
                    eventImage: action.payload.event.image,
                    accommodation: {
                        accommodationTitle: action.payload.event.accommodations?.length > 0 ?
                            action.payload.event.accommodations[0]?.title : '',
                        accommodationContent: action.payload.event.accommodations?.length > 0 ?
                            action.payload.event.accommodations[0]?.content : '',
                        accommodationDate: action.payload.event.accommodations[0].date ?
                            convertDateTimeFormat(action.payload.event.accommodations[0].date).toString() : 'TBA',
                    }
                },
                loading: false,
                decideLater: {
                    eventDate: action.payload.event.event_date !== null ? false : true,
                    registerOnTime: action.payload.event.register_by !== null ? false : true,
                    finalLatePaymentBy: action.payload.event.final_payment_by !== null ? false : true,
                    fullRefundBy: action.payload.event.refund_full !== null ? false : true,
                    halfRefundBy: action.payload.event.refund_half !== null ? false : true,
                    noRefund: action.payload.event.refund_not !== null ? false : true,
                    accommodationDate: action.payload.event.accommodations.date !== null ? false : true
                }
            }
        },
        getEventDataByIdFailure: (state, action) => {
            return {
                ...state,
                errors: [],
                loading: false
            }
        },
        drawerToggle: (state, action) => {
            state.drawerToggle = action.payload
        }
    }
})

function convertDateTimeFormat(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
}

function convertDateTimeFormat2(str) {
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(str).toLocaleDateString('en-US', dateOptions);
    return date;
}

// Action creators are generated for each case reducer function
export const {
    getEventDataById,
    editEventLoading,
    getEventDataByIdSuccess,
    getEventDataByIdFailure,
    drawerToggle,
} = eventdetailSlice.actions

export default eventdetailSlice.reducer