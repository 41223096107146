import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getaboutData, getSliderData } from "./aboutSlice";
import ContentPageCarousel from "../contentpagecarousel/contentpagecarousel";

const { REACT_APP_API_URL } = process.env;
export function About() {
  const mounted = useSelector((state) => state?.aboutData?.mounted);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!mounted) {
      getAbout();
      //getSlider();
    }
  });
  const aboutContent = useSelector((state) => state?.aboutData?.about);
  const slider = useSelector((state) => state?.aboutData?.about?.slider);
  // const slider = useSelector((state) => state?.aboutData?.slider);

  const getAbout = () => {
    fetch(`${REACT_APP_API_URL}page/detail/ABOUT_US_ID/ABOUT_US`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getaboutData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSlider = () => {
    fetch(`${REACT_APP_API_URL}fe-contentpage-slider`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          dispatch(getSliderData(responseJson.data));
        } else {
          console.log(responseJson);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container className="fluidContainer padLeftNone">
        <div className="joinOurTeamWrapper">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* <Aboutcarousel /> */}
              {!!slider && <ContentPageCarousel images={slider} />}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="aboutWrapper">
                <Typography variant="h1">{aboutContent?.title}</Typography>
                <div className="rulesContent">
                  <div
                    className="ckeditor_content_render"
                    dangerouslySetInnerHTML={{ __html: aboutContent?.content }}
                  ></div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default About;
