import React from 'react';
import Container from '@mui/material/Container';
import { Navigation } from '../navigation/navigation';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function Header() {

    const location = useLocation();
    var headerClass = 'activeRoute';
    if (location.pathname === '/') {
        headerClass = '';
    }

    const headerLogo = useSelector(state => state.header.websiteSettings.header_logo)

    return (
        <>
            <header className={`topHeader ${headerClass}`}>
                <Container maxWidth="lg" className='containerFe'>
                    <div className='headerInner'>
                        <div className='logo'><a href='/'><img src={headerLogo} alt='' /></a></div>
                        <Navigation />
                    </div>
                </Container>
            </header>
        </>
    )
}

export default Header;