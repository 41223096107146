import React from "react";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";

export function ContentPageCarousel(props) {
  return (
    <>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop={true}
        className="joinOurTeamCarousel"
      >
        {props.images.map((slide, index) => (
          <div className="bannerSlideWrapper" key={index}>
            <a href={slide.link} target="_blank" rel="noreferrer">
              <div
                className="bannerSlide"
                style={{ backgroundImage: "url(" + slide.image + ")" }}
              ></div>
            </a>
          </div>
        ))}
      </Carousel>
    </>
  );
}
ContentPageCarousel.propTypes = {
  images: PropTypes.array,
};

export default ContentPageCarousel;
