import { createSlice } from "@reduxjs/toolkit";

export const competitionguidelinesSlice = createSlice({
  name: "competitionguidelinesData",
  initialState: {
    competitionguidelines: {
      title: "",
      register_pdf: "",
      policie_pdf: "",
      youth_cat_division_pdf: "",
      youth_cheer_rule_score_pdf: "",
      youth_dance_rule_score_pdf: "",
      na_cat_division_pdf: "",
      na_cheer_rule_score_pdf: "",
      na_dance_rule_score_pdf: "",
      yacda_membership: "",
      yacda_video_library: "",
    },
    slider: "",
    mounted: false,
    error: "",
  },
  reducers: {
    getcompetitionguidelinesData: (state, action) => {
      return {
        ...state,
        competitionguidelines: action.payload,
        mounted: true,
      };
    },
    getSliderData: (state, action) => {
      return {
        ...state,
        slider: action.payload,
        mounted: true,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { getcompetitionguidelinesData, getSliderData } =
  competitionguidelinesSlice.actions;

export default competitionguidelinesSlice.reducer;
