import { Container, Grid } from '@mui/material';
import React from 'react';
import Ctacard from '../ctacard/ctacard';

export function Homepagecta() {

    return (
        <>
            <Container spacing={2} className='containerFe'>
                <Grid item xs={12}>
                    <div className='ctaCardWrapper'>
                        <Ctacard />
                    </div>
                </Grid>
            </Container>
        </>
    )
}

export default Homepagecta;
