import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const Phonenumber = ({ hasIcon, icon }) => {
  const icontype = icon ? "phone-icon--" + icon : "";
  const PhonenumberHasIcon = hasIcon ? "has-icon" : "no-icon";
  const FaxHasIcon = hasIcon ? "fax-has-icon" : "no-icon";

  var phoneIcon = "";
  if (icontype === "phone-number-icon") {
    phoneIcon = <div className="phone-icon"></div>;
  }

  const faxNumberLink = useSelector(
    (state) => state?.header?.websiteSettings?.fax
  );

  function formatNumber(number) {
    if (number) {
      return number
        .replace(/\D+/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return;
  }

  const phoneNumberLink = useSelector(
    (state) => state.header.websiteSettings.phone_number
  );

  return (
    <React.Fragment>
      <div className="hp-phonenumber--wrapper">
        {phoneIcon}
        <span className={["hp-phonenumber", PhonenumberHasIcon].join(" ")}>
          <a href={"tel:" + phoneNumberLink}>
            {" "}
            {formatNumber(phoneNumberLink)}
          </a>
        </span>
      </div>
      <div className="hp-phonenumber--wrapper hasFaxNumber">
        {phoneIcon}
        <span className={["hp-phonenumber", FaxHasIcon].join(" ")}>
          <a href={"#"}> {formatNumber(faxNumberLink)}</a>
        </span>
      </div>
    </React.Fragment>
  );
};

Phonenumber.propTypes = {
  hasIcon: PropTypes.bool,
};

export default Phonenumber;
